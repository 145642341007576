$color_1: #07679b;
$color_2: #2b2b2b;
$color_3: #00a9ce;
$color_4: #fff;
$font_family_1: "Helvetica Neue", "Open Sans" sans-serif !important;
$background_color_1: #fff;
$background_color_2: white;
$background_color_3: #f2f2f2;
$background_color_4: #00a9ce;
$background_color_5: #f3f4f4;
$background_color_6: #0071b7;
$border_color_1: transparent;
$border_bottom_color_1: rgba(0, 0, 0, 0.25);
$border_bottom_color_2: #fff;

@font-face {
     font-family: "Helvetica Neue";
     src: url("../../../client_customizations/assets/HelveticaNeueLTPro-Bd.otf") format("opentype");
}

@font-face {
     font-family: "Helvetica Neue Roman";
     src: url("../../../client_customizations/assets/HelveticaNeueLTPro-Roman.otf") format("opentype");
}
@font-face {
     font-family: "Helvetica Neue Thin";
     src: url("../../../client_customizations/assets/Helvetica-Neue-LT-Std-35-Thin.ttf") format("opentype");
}

.roman {
     font-family: "Helvetica Neue Roman" !important;
}
body {
     background-color: $background_color_1;
     font-family: "Helvetica Neue";
     font-size: 13px;
}
h1 {
     font-size: 22px;
}
.h1 {
     font-size: 22px;
}
h2 {
     font-size: 18px;
}
.h2 {
     font-family: "Helvetica Neue" !important;
     font-size: 18px;
}
.modal-title {
     font-size: 24px;
     font-weight: 700;
}
@media (min-width: 768px) {
     .modal-content {
          width: 100%;
     }
}
.modal-smaller {
     width: 35%;
}
h3 {
     font-size: 16px;
}
.h3 {
     font-size: 16px;
     font-family: "Helvetica Neue Roman" !important;
}
h4 {
     font-family: "Helvetica Neue Thin" !important;
     font-size: 12px;
}
.h4 {
     font-size: 12px;
}
h5 {
     font-size: 10px;
}
.h5 {
     font-size: 10px;
}
.lead {
     font-size: 16px;
     font-family: "Helvetica Neue Thin";
}
a {
     color: $color_1;
}
table {
     table-layout: fixed;
     tbody {
          th {
               font-weight: normal;
          }
          tr {
               th {
                    &:first-child {
                         text-align: left;
                    }
                    word-break: break-all;
               }
          }
     }
     tfoot {
          tr {
               th {
                    &:first-child {
                         text-align: left;
                    }
                    word-break: break-all;
               }
          }
     }
}
table.table-bordered {
     tfoot {
          td {
               font-weight: bold;
               border-top: 2px solid #dee2e6;
          }
          th {
               font-weight: bold;
               border-top: 2px solid #dee2e6;
          }
     }
     th {
          word-break: break-all;
     }
}
button.btn-sm {
     font-size: 80%;
}
.input-well {
     font-family: "Helvetica Neue Roman" !important;
     .button.btn-sm {
          font-size: 80%;
     }
     background-color: $background_color_2;
     padding: 25px;
     border-radius: 4px;
     border: 1px solid #dbdbdb;
     .form-group {
          margin-bottom: 1.5rem;
          p {
               font-size: 12px;
               color: #757575;
               margin-bottom: 1rem;
          }
     }
     form {
          .form-group {
               &:last-child {
                    margin-bottom: 0;
               }
          }
     }
     label {
          font-family: "Helvetica Neue" !important;
          font-weight: 700;
          font-size: 12px;
          color: $color_2;
          text-transform: uppercase;
          margin-bottom: 10px;
          display: flex;
     }
     .label-style {
          font-weight: 700;
          font-size: 12px;
          color: $color_2;
          text-transform: uppercase;
          margin-bottom: 0;
     }
     .btn {
          display: inline-block;
          border: 1px solid #dbdbdb;
          font-weight: 600;
          font-size: 13px;
          background-color: #00819e;
          color: white;
          border-radius: 1px;
          height: 40px;
     }
     .btn.active {
          background-color: $background_color_4;
          border: 1px solid #5ca4d1;
     }
     .btn-block-container {
          .filter-label {
               font-family: "Helvetica Neue Roman" !important;
               display: flex;
               align-items: center;
               width: 100%;
               height: 40px;
               position: relative;
               .name {
                    font-size: 14px;
                    width: 80px;
                    margin-left: 1.5px;
               }
               .switch {
                    position: absolute;
                    right: 0;
               }
          }
          .btn {
               display: block;
               width: 100%;
               margin-bottom: 6px;
               text-align: left;
               .badge {
                    background-color: $background_color_2;
                    border: 1px solid #dbdbdb;
                    margin-right: 5px;
                    img {
                         width: 20px;

                         padding-right: 17px;
                    }
               }
               .filter-label {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 40px;

                    .name {
                         display: inline-flex;
                         white-space: nowrap;
                    }
                    .switch {
                         position: absolute;
                         left: 70%;
                         .react-switch-bg {
                              width: 50px !important;
                         }
                    }
               }
               .filter-switch {
                    display: flex;
               }
               .first {
                    margin-bottom: 10px;
               }
          }
     }
     .btn-grid-container {
          font-family: "Helvetica Neue" !important;
          display: flex;
          flex-wrap: wrap;
          button {
               width: 48%;
               margin: 2%;
               &:nth-of-type(2n) {
                    margin-right: 0;
               }
               &:nth-of-type(2n + 1) {
                    margin-left: 0;
               }
          }
     }
     .btn-ae {
          padding: 6px 12px;
          border-radius: 0;
          border: 0;
          color: $color_4;
          font-weight: 600;
          background-color: #00819e;
     }
     .btn.active {
          background-color: $color_4;
          color: #00819e;
     }
}
.btn-default {
     border: 1px solid #ced4da;
}
strong {
     font-weight: 500;
}
hr {
     border-top: 2px solid rgba(0, 0, 0, 0.8);
     margin: 0;
     padding: 0;
}
.arrow-up {
     top: calc((1rem - 1px) * -1);
     position: absolute;
     display: block;
     width: 1rem;
     height: 1rem;
     margin: 0 0.3rem;
     &:before {
          position: absolute;
          display: block;
          content: "";
          border-color: $border_color_1;
          border-style: solid;
          top: 0;
          border-width: 0 1rem 1rem 1rem;
          border-bottom-color: $border_bottom_color_1;
     }
     &:after {
          position: absolute;
          display: block;
          content: "";
          border-color: $border_color_1;
          border-style: solid;
          top: 1px;
          border-width: 0 1rem 1rem 1rem;
          border-bottom-color: $border_bottom_color_2;
     }
}
.background-grey {
     background-color: $background_color_5;
}
.bottom-thick-margin {
     border-bottom: 2px solid #000000;
}
.color-primary {
     color: $color_3;
}
.btn-ae {
     padding: 6px 12px;
     border-radius: 0;
     border: 0;
     color: $color_4;
     font-weight: 600;
     background-color: #00819e;
     &:hover {
          border-radius: 0;
          color: $color_4;
          font-weight: 600;
          text-decoration: none;
          background-color: $background_color_6;
     }
}
.btn-homepage {
     padding: 10px 20px;
     border-radius: 0;
     border: 0;
     color: $color_4;
     font-weight: 600;
     background-color: #00819e;
     &:hover {
          border-radius: 0;
          color: $color_4;
          font-weight: 600;
          text-decoration: none;
          // background-color: $background_color_6;
     }
}
.PageLayout {
     a.btn-ae {
          &:hover {
               border-radius: 0;
               color: $color_4;
               font-weight: 600;
               text-decoration: none;
               background-color: $background_color_6;
          }
     }
}
a.btn-ae {
     &:hover {
          border-radius: 0;
          color: $color_4;
          font-weight: 600;
          text-decoration: none;
          background-color: $background_color_6;
     }
}
.bottom-margin {
     margin-bottom: 30px !important;
}
.top-margin {
     margin-top: 40px !important;
}
.btn-arrow {
     font-family: "Helvetica Neue" !important;
     background: none;
     border: none;
     color: black;
     font-weight: 600;
     font-size: 12px;
}
#vehicle-sort-dropdown {
     font-family: "Helvetica Neue Roman" !important;
     background: url(../../assets/images/Triangle.svg);
     background-position: 95%;
     background-size: 0.5em 0.5em;
     background-repeat: no-repeat;
     background-color: $background_color_1;
}
.mb-0 {
     margin-top: 5px;
     color: $color_2;
}
.btn-wide {
     width: 100%;
     padding: 12px 12px;
     border-radius: 0;
     border: 0;
     color: $color_4;
     font-weight: 600;
     background-color: $background_color_4;
}
.btn-jumbotron-white {
     padding: 0.75rem 2rem;
     border-radius: 0;
     border: 0;
     color: #00819e;
     font-weight: 600;
     background-color: $background_color_2;
     border: 1px solid $color_3;
     width: 100%;
}
.btn-jumbotron {
     padding: 0.75rem 2rem;
     border-radius: 0;
     border: 0;
     color: $color_4;
     font-weight: 600;
     background-color: #00819e;
     width: 100%;
}

.show-hide-btn {
     font-family: "Helvetica Neue" !important;
     font-size: 16px;
     padding: 0.75rem 3rem;
     border-radius: 0;
     border: 0;
     color: #00819e;
     font-weight: 600;
     background-color: $background_color_2;
     border: 1px solid $color_3;
}
.show-hide-btn-arrow {
     width: 100%;
     border-radius: 0;
     font-weight: 600;
     padding: 0.75rem 2rem;
     color: $color_3 !important;
     background-color: $background_color_2 !important;
     border: 1px solid $color_3 !important;
     background: url(../../assets/images/blue-triangle.svg);
     background-position: 95%;
     background-size: 0.75em 0.75em;
     background-repeat: no-repeat;
}
.compare-title {
     font-family: "Helvetica Neue Roman";
     font-weight: 400;
     font-size: 24px;
     display: flex;
     justify-content: center;
     margin-bottom: 40px;
}
.assumption {
     font-family: "Helvetica Neue Roman";
}
.graph-head {
     font-weight: 400;
     font-size: 18px;
     margin: 10px;
}
.graph-title {
     font-family: "Helvetica Neue Roman" !important;
     font-weight: 400;
     font-size: 16px;
     margin: 10px;
}
.incentives-title {
     font-weight: 700;
     font-size: 24px;
     margin-bottom: 40px;
}
.selected-subText {
     font-family: "Helvetica Neue Thin" !important;
     display: flex;
     margin: 10px;
     font-weight: 200;
     color: #666666;
     font-size: 14px;
}
.shadowed {
     box-shadow: 0 20px 41px 0 rgba(0, 0, 0, 0.1);
}
.line-box {
     display: flex !important;
}
.savings-info {
     font-weight: 400;
     font-size: 18px;
     margin-top: 5%;
}
.chart-info {
     width: 85%;
}
.graph-subtitle {
     margin-bottom: 15px;
}
@media (min-width: 576px) {
     .modal-dialog {
          max-width: 800px;
          margin: 1.75rem auto;
     }
     .modal-dialog {
          max-width: 800px;
          margin: 1.75rem auto;
     }
}
#Incentives,
.CompareVehicles {
     background-color: #f3f4f4;
}
.white {
     background-color: white !important;
}

.incentive-label-select {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 700;
     font-size: 12px;
     line-height: 16px;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-align: center;
     align-items: center;
     text-transform: uppercase;
     color: #2a2a2a;
}
.compare-select-title {
     font-weight: bold;
     font-size: 12px;
     color: #000;
     letter-spacing: 0px;
     line-height: 20px;
     text-transform: uppercase;
     margin-bottom: 0.5rem;
}
.graph-sub-title {
     font-weight: 700 !important;
     font-size: 16px;
     text-align: center;
}
.margin {
     margin-bottom: 2rem;
}
.bubble-title {
     display: flex;
     justify-content: center;
     align-items: center;
     margin: 1rem;
}
.bubble-icon {
     width: 40px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* IE10+ CSS */
     .select-ae {
          background-size: 0;
     }
}
.card-position {
     justify-content: center;

     @media (max-width: 768px) {
          justify-content: center !important;
     }
}
