.fuel-graphic {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150;
    width: 150;

    .fuel-graphic-bubbles {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .hybrid-image {
        width: 75%;
    }

    .fuel-graphic-text {
        font-weight: bold;
        font-size: 14px;
    }
}

.costOfOwnership {
    .graph-title {
        display: flex;
        justify-content: center;
        padding-top: 8;
        padding-bottom: 8;

        .graph-box {
            display: flex;
            flex-direction: column;
            align-items: center;

            .strong-green {
                color: rgb(33, 135, 57);
                font-weight: 400;
            }
        }
    }

    .fuel-cost-boxes {
        display: flex;
        justify-content: space-around;
        width: 100%;

        @media(max-width:450px) {
            flex-wrap: wrap;
        }

        .fuel-cost-box {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .bubble-title {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0px;
                text-align: center;
            }
        }
    }
}