@import "src/client_customizations/scss/client_master.scss";

/* evc-card */
/* Card Layouts */
h1 {
     font-weight: 700;
     font-size: 32px;
}
h2 {
     font-weight: 600;
}
h3 {
     font-weight: 600;
}
h4 {
     line-height: 30px;
     font-weight: 300;
     font-size: 16px;
}
h5 {
     font-weight: 600;
}
.h1 {
     font-weight: 700;
     font-size: 32px;
}
.h2 {
     font-weight: 600;
}
.h3 {
     font-weight: 400;
     strong {
          color: $PageLayout-header-bold-color;
     }
}
.h4 {
     font-size: 16px;
     font-weight: 300;
     strong {
          color: $PageLayout-header-bold-color;
     }
}
.h5 {
     font-weight: 600;
}
strong {
     font-weight: 600;
}
.centered {
     display: flex;
     flex-direction: column;
     align-items: center;
}
.aligned {
     text-align: center;
}
.grey {
     background-color: #f4f4f4;
}
.less-top-margin {
     margin-top: -10px !important;
}
a {
     &:focus {
          box-shadow: $PageLayout-link-focus-box-shadow;
          outline: 0;
     }
}
button,
.btn {
     &:focus {
          box-shadow: $PageLayout-button-focus-box-shadow;
          outline: 0;
     }
}
.form-control {
     font-family: "Helvetica Neue Roman";
     &:focus {
          box-shadow: $PageLayout-form-control-focus-box-shadow;
          outline: 0;
     }
}
.zip-control {
     &:focus {
          box-shadow: $PageLayout-form-control-focus-box-shadow;
          outline: 0;
     }
}
input {
     &:focus {
          box-shadow: $PageLayout-input-focus-box-shadow;
          outline: 0;
     }
}
select {
     &:focus {
          box-shadow: $PageLayout-select-focus-box-shadow;
          outline: 0;
     }
}
textarea {
     &:focus {
          box-shadow: $PageLayout-textarea-focus-box-shadow;
          outline: 0;
     }
}
.image-gallery-thumbnail {
     &:focus {
          box-shadow: $PageLayout-image-gallery-thumbnail-focus-box-shadow;
          outline: 0;
     }
}
.SimpleCopyPageBody {
     .card {
          &:focus {
               box-shadow: $PageLayout-SimpleCopyPageBody-card-focus-box-shadow;
               outline: 0;
          }
          background-color: $PageLayout-SimpleCopyPageBody-card-background-color;
          &:hover {
               box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
          }
          &:-webkit-any-link {
               &:hover {
                    color: $PageLayout-SimpleCopyPageBody-card-link-hover-color;
                    text-decoration: none;
               }
          }
          a {
               &:hover {
                    color: $PageLayout-SimpleCopyPageBody-card-link-hover-color;
                    text-decoration: none;
               }
          }
     }
     padding-bottom: 400px;
     p.h4 {
          color: $PageLayout-SimpleCopyPageBody-card-header-color;
          margin-top: 30px;
          font-weight: 500;
     }
}
.evc-card {
     &:focus {
          box-shadow: $focus-box-shadow;
          outline: 0;
     }
     background-color: $PageLayout-evc-card-focus-box-shadow;
     font-size: $PageLayout-evc-card-font-size;
     border-radius: 4px;
     color: $PageLayout-evc-card-color;
     width: 250px;
     display: inline-block;
     cursor: pointer;
     &:hover {
          border: $PageLayout-evc-card-hover-border;
          box-shadow: $PageLayout-evc-card-hover-box-shadow;
          color: $PageLayout-evc-card-hover-color;
          text-decoration: none;
     }
}
.SocialMediaShareButton {
     &:focus {
          box-shadow: $PageLayout-socialmedia-focus-box-shadow;
          outline: 0;
     }
}
.clickable-faq {
     &:focus {
          box-shadow: $PageLayout-faq-focus-box-shadow;
          outline: 0;
     }
     color: $PageLayout-faq-focus-color;
     display: block;
     text-decoration: none;
     padding: 1rem;
     cursor: pointer;
}
.evc-card.selected {
     box-shadow: none;
}
#logo {
     &:focus {
          box-shadow: none;
     }
}
.PageLayout {
     a {
          &:hover {
               text-decoration: underline;
          }
     }
}
section.container-fluid {
     padding: 2rem 1rem;
}
section.container {
     padding: 2rem 1rem;
     background-color: $PageLayout-container-background-color;
}
.hide-offscreen {
     position: absolute;
     top: -1000px;
     left: -1000px;
     height: 1px;
     width: 1px;
}
.clear-bottom-margin {
     margin-bottom: 0 !important;
}
.small-bottom-margin {
     margin-bottom: 5px !important;
}
.legal-disclaimer {
     font-family: "Helvetica Neue Thin" !important;
     margin-top: 20px;
     font-size: 12px;
     font-weight: 400;
     letter-spacing: 0;
     color: $PageLayout-legal-disclaimer-color;
}
.legal-sub-disclaimer {
     font-family: "Helvetica Neue Thin" !important;
     margin-top: 20px;
     font-size: 12px;
     font-weight: 300;
     letter-spacing: 0;
     color: $PageLayout-legal-disclaimer-color;
}
.sticky {
     position: sticky;
     position: -webkit-sticky;
     top: 50px;
}
.renderRowOfData {
     display: flex;
     flex-direction: column;
     p {
          span {
               float: right;
               font-weight: 300;
          }
          @media (min-width: 768px) {
               small {
                    font-weight: 300;
                    font-size: 14px;
               }
          }
     }
}
.h6 {
     font-weight: 700;
     font-size: 16px;
     strong {
          color: $PageLayout-header-strong-color;
     }
}
.SimpleCopyPageHeader {
     color: $PageLayout-SimpleCopy-color;
}
a.supporting-link {
     color: $PageLayout-SimpleCopy-color;
     margin-top: 0;
     font-weight: 700;
     font-size: 12px;
}
.evc-card.not-selected {
     height: 100%;
}
a.evc-card {
     &:hover {
          border: $PageLayout-evc-card-hover-border;
          box-shadow: $PageLayout-evc-card-hover-box-shadow;
          color: $PageLayout-evc-card-hover-color;
          text-decoration: none;
     }
}
.render-cards-container {
     display: flex;
     flex-wrap: wrap;
     .evc-card {
          width: 48%;
          margin: 1%;

          @media (max-width: 546px) {
               width: 100%;
               padding: 15px;
          }
     }
}
#HomepageIncentives {
     background-color: #ffff;
}
@media (min-width: 768px) {
     section.container {
          padding: 2rem 2rem;
     }
}
@media (min-width: 992px) {
     section.container {
          padding: 2rem 3rem;
     }
     .render-cards-container {
          .evc-card {
               width: 49%;
               margin: 1%;
          }
          > * {
               &:nth-of-type(2n) {
                    margin-right: 0;
               }
               &:nth-of-type(2n + 1) {
                    margin-left: 0;
               }
               &:first-child {
                    margin-top: 0;
               }
               &:nth-child(2) {
                    margin-top: 0;
               }
          }
     }
     .render-cards-container.full-width {
          .evc-card {
               width: 23.5%;
               margin: 1%;
          }
          > * {
               &:nth-of-type(4n) {
                    margin-right: 0;
               }
               &:nth-of-type(4n + 1) {
                    margin-left: 0;
               }
          }
     }
}
.price-panels-info {
     font-family: "Helvetica Neue Roman" !important;
     font-weight: 400;
     font-size: 40px;
}

.price-panels-info-sub {
     font-family: "Helvetica Neue Roman" !important;
     font-weight: 400;
     font-size: 24px;
}
.form-control {
     appearance: none;
     background: url(../../assets/images/Triangle.svg);
     background-position: 95%;
     font-size: 13px;
     background-size: 0.5em 0.5em;
     background-repeat: no-repeat;
     background-color: #fff;
     color: #666666;
}
.BEV {
     -webkit-appearance: none;
     -moz-appearance: none;
     background-image: url(../../assets/images/icons/Electric.svg), url(../../assets/images/Triangle.svg);
     background-position: 5%, 95%;
     background-size: 1.25em 1.25em, .5em .5em;
     padding-left: 30px;
     background-repeat: no-repeat, no-repeat;

     &:focus {
          outline: 0;
     }
}

.gas {
     -webkit-appearance: none;
     -moz-appearance: none;
     background-image: url(../../assets/images/icons/Gas.svg), url(../../assets/images/Triangle.svg);
     background-position: 5%, 95%;
     background-size: 1em 1em, .5em .5em;
     padding-left: 35px;
     background-repeat: no-repeat, no-repeat;

     &:focus {
          outline: 0;
     }
}

.PHEV {
     -webkit-appearance: none;
     -moz-appearance: none;
     background-image: url(../../assets/images/icons/PHEV.svg), url(../../assets/images/Triangle.svg);
     background-position: 5%, 95%;
     background-size: 3em 3em, .5em .5em;
     padding-left: 55px;
     background-repeat: no-repeat, no-repeat;

     &:focus {
          outline: 0;
     }
}
.zipcode {
     background: none !important;
}
@media (min-width: 1200px) {
     .evc-card.not-selected {
          height: 100%;
     }
     .render-cards-container {
          .evc-card {
               width: 32%;
               margin: 1%;
          }
          > * {
               &:nth-of-type(3n) {
                    margin-right: 0;
               }
               &:nth-of-type(3n + 1) {
                    margin-left: 0;
               }
               &:first-child {
                    margin-top: 0;
               }
               &:nth-child(2) {
                    margin-top: 0;
               }
               &:nth-child(3) {
                    margin-top: 0;
               }
          }
     }
}
